
    import { Component, Prop, Mixins } from "vue-property-decorator";
    import { Route, RawLocation } from "vue-router";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import moment from "moment";
    import ReservationPayload from "@/store/modules/reservation/models";
    import RoutesEnum from "@/router/routesEnum";
    import BookingInfo from "@/app/reservation/components/BookingInfo.vue";
    import ProductPayload from "@/store/modules/product/models";
    import ReservationTokenModel from "@/models/reservationTokenModel";
    import { AxiosError } from "axios";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import CustomerProject from "@/models/customerProject";
    import { PreviewClientQuery } from "@/models/previewClientQuery";
    import { ProductDetailImageModel, ProductDetailProductGroupModel, ProductImageQuery } from "@/apiManager/product/productData";
    import { ReservationBody, ReservationBodyOption, ReservationModel, ReservationProductCategoryModel, ReservationProductGroupModel, ReservationProductModel } from "@/apiManager/reservation/reservationData";
    import { SettingModel } from "@/apiManager/setting/settingData";
    import { ProductDisplayModeEnum } from "@/apiManager/_common/enums";

    //#region validateQuery
    let validateQuery = (p_Query: PreviewClientQuery): boolean =>
    {
        if ((p_Query.prod === undefined) || (p_Query.prodPDat === undefined) || (p_Query.prodPCla === undefined) || (p_Query.prodPCat === undefined) || (p_Query.pricCla === undefined) || (p_Query.pricCat === undefined) || (p_Query.dtDep === undefined) || (p_Query.dtRet === undefined) || (p_Query.nbA === undefined) || (p_Query.nbC === undefined) || (p_Query.nbR === undefined))
        {
            return false;
        }
        if ((p_Query.prod.length === 0) || (p_Query.dtDep.length === 0) || (p_Query.dtRet.length === 0))
        {
            return false;
        }
        if ((p_Query.prodPDat < 1) || (p_Query.prodPCla < 1) || (p_Query.prodPCat < 1) || (p_Query.pricCla < 1) || (p_Query.pricCat < 1) || (p_Query.nbA < 1) || (p_Query.nbC < 0) || (p_Query.nbR < 1))
        {
            return false;
        }
        if ((!moment(p_Query.dtDep as string).isValid()) || (!moment(p_Query.dtRet as string).isValid()))
        {
            return false;
        }

        return true;
    };
    //#endregion

    @Component
    export default class TravelBag extends Mixins(BeforeRouteEnterMixin)
    {
        private isReservation: boolean = false;
        private product: string = "";
        private productPriceDateCode: number = 0;
        private productPriceClassCode: number = 0;
        private productPriceCategoryCode: number = 0;
        private pricingClassCode: number = 0;
        private pricingCategoryCode: number = 0;
        private dateDepart: string = "";
        private dateReturn: string = "";
        private nbAdult: number = 0;
        private nbChild: number = 0;
        private nbRoom: number = 0;
        private listOption: ReservationBodyOption[] = [];
        private busPickup: number = 0;
        private hasError: boolean = false;
        private isCreateReservationInProgress: boolean = false;
        private reservation: ReservationModel = new ReservationModel();

        @Prop({ type: String, default: "" }) private readonly productGroupSelected!: string;

        onClickBack()
        {
            if (this.listOption !== null)
            {
                let queryData: PreviewClientQuery = {
                    prod: this.product,
                    prodPDat: this.productPriceDateCode,
                    prodPCla: this.productPriceClassCode,
                    prodPCat: this.productPriceCategoryCode,
                    pricCla: this.pricingClassCode,
                    pricCat: this.pricingCategoryCode,
                    dtDep: this.dateDepart,
                    dtRet: this.dateReturn,
                    nbA: this.nbAdult,
                    nbC: this.nbChild,
                    nbR: this.nbRoom,
                    opt: this.listOption,
                    pickup: this.busPickup
                };

                this.$router.push({
                    name: RoutesEnum.Options,
                    query: {
                        data: encodeURIComponent(btoa(JSON.stringify(queryData)))
                    },
                    params: {
                        productGroupSelected: this.productGroupSelected
                    }
                });
            }
            else
            {
                this.$router.push({
                    name: RoutesEnum.ProductDetail,
                    query:
                    {
                        prod: this.product,
                        prodPDat: this.productPriceDateCode.toString(),
                        dtDep: this.dateDepart,
                        dtRet: this.dateReturn
                    }
                });
            }
        }

        get components()
        {
            let components =
                {
                    "booking-info": BookingInfo
                };

            return components;
        }

        get getUseMainLanguage(): boolean
        {
            return this.$tStore.state.configState.useMainLanguage;
        }

        get g_Setting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get getProduct(): ReservationProductModel
        {
            let product: ReservationProductModel;

            if (this.g_Reservation.mainProduct)
            {
                product = this.g_Reservation.mainProduct;
            }
            else
            {
                product = new ReservationProductModel();
            }

            return product;
        }

        get getProductGroupSelected(): ReservationProductGroupModel[]
        {
            if (this.productGroupSelected.length > 0)
            {
                return this.reservation.listProductGroup.filter((group) =>
                {
                    return group.code === parseInt(this.productGroupSelected);
                });
            }
            else
            {
                return this.reservation.listProductGroup;
            }
        }

        get getProductCategoryList(): ReservationProductCategoryModel[]
        {
            let listCateogry: ReservationProductCategoryModel[] = [];
            listCateogry.push(this.getProduct.categoryAdult);
            if (this.getProduct.categoryChild.quantity > 0)
            {
                listCateogry.push(this.getProduct.categoryChild);
            }
            return listCateogry;
        }

        get getReservation(): ReservationModel
        {
            return this.$tStore.state.reservationState.detail;
        }

        get getDurationLabel(): string
        {
            if (this.getProduct.durationInNights)
            {
                return (this.getProduct.duration - 1) + " " + this.i18n(this.getRezToursLanguageEnum.nights);
            }
            else
            {
                return this.getProduct.duration + " " + this.i18n(this.getRezToursLanguageEnum.days);
            }
        }

        get getBusPickupLabel(): string
        {
            if ((this.g_Reservation.busPickup !== null) && (this.g_Reservation.busPickup !== undefined))
            {
                return `${ this.g_Reservation.busPickup.time } - ${ this.g_Reservation.busPickup.name }`;
            }
            else
            {
                return "";
            }
        }

        get g_Reservation(): ReservationModel
        {
            if (this.isReservation)
            {
                let reservation = new ReservationModel();
                reservation.dateDepart = this.getReservation.dateDepart;
                reservation.dateReturn = this.getReservation.dateReturn;
                reservation.origin = this.getReservation.origin;
                reservation.destination = this.getReservation.destination;
                reservation.total = this.getReservation.total;
                reservation.currency = this.getReservation.currency;
                reservation.mainProduct = this.getReservation.mainProduct;
                reservation.listOption = this.getReservation.listOption;
                reservation.deposit = this.getReservation.deposit;
                reservation.busPickup = this.getReservation.busPickup;
                return reservation;
            }
            else
            {
                return this.reservation;
            }
        }

        get getBtnNextText(): string
        {
            let token: ReservationTokenModel = new ReservationTokenModel();
            if (this.$route.query.code || token.valid())
            {
                return this.i18n(this.getRezToursLanguageEnum.next).toUpperCase();
            }
            else
            {
                return this.i18n(this.getRezToursLanguageEnum.getBooking).toUpperCase();
            }
        }

        get getIsTestMode(): boolean
        {
            return this.g_Setting.license.testMode || this.getProduct.displayMode === ProductDisplayModeEnum.Test;
        }

        get getIsBtnNextDisabled(): boolean
        {
            return this.getIsTestMode || this.isCreateReservationInProgress;
        }

        beforeRouteEnter(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (p_To.query.code)
            {
                return p_Next();
            }

            let queryData: PreviewClientQuery = JSON.parse(atob(decodeURIComponent(p_To.query.data?.toString() ?? "")));
            if (validateQuery(queryData))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        beforeRouteUpdate(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (p_To.query.code)
            {
                return p_Next();
            }

            let queryData: PreviewClientQuery = JSON.parse(atob(decodeURIComponent(p_To.query.data?.toString() ?? "")));
            if (validateQuery(queryData))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        async beforeMount()
        {
            this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(true));
            let token: ReservationTokenModel = new ReservationTokenModel();
            if (this.$route.query.code || token.valid())
            {
                this.isReservation = true;
            }
            else
            {
                let queryData: PreviewClientQuery = JSON.parse(atob(decodeURIComponent(this.$route.query.data?.toString() ?? "")));
                this.product = queryData?.prod ?? "";
                this.productPriceDateCode = queryData?.prodPDat ?? 0;
                this.productPriceClassCode = queryData?.prodPCla ?? 0;
                this.productPriceCategoryCode = queryData?.prodPCat ?? 0;
                this.pricingClassCode = queryData?.pricCla ?? 0;
                this.pricingCategoryCode = queryData?.pricCat ?? 0;
                this.dateDepart = queryData?.dtDep ?? "";
                this.dateReturn = queryData?.dtRet ?? "";
                this.nbAdult = queryData?.nbA ?? 0;
                this.nbChild = queryData?.nbC ?? 0;
                this.nbRoom = queryData?.nbR ?? 0;
                this.listOption = queryData?.opt ?? [];
                this.reservation = new ReservationModel();
                this.busPickup = queryData?.pickup ?? 0;

                let body: ReservationBody = new ReservationBody();
                body.product = this.product;
                body.productPriceDateCode = this.productPriceDateCode;
                body.productPriceClassCode = this.productPriceClassCode;
                body.productPriceCategoryCode = this.productPriceCategoryCode;
                body.pricingClassCode = this.pricingClassCode;
                body.pricingCategoryCode = this.pricingCategoryCode;
                body.dateDepart = this.dateDepart;
                body.dateReturn = this.dateReturn;
                body.nbAdult = this.nbAdult;
                body.nbChild = this.nbChild;
                body.nbRoom = this.nbRoom;
                body.busPickup = this.busPickup ?? 0;
                body.project = CustomerProject.Get();
                body.listOption = this.listOption;

                try
                {
                    this.reservation = await this.$apiManager.reservation.getPreviewAsync(body);
                }
                catch (ex)
                {
                    this.hasError = true;
                    this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(false));
                }

                try
                {
                    let queryImage: ProductImageQuery = new ProductImageQuery(this.product);
                    let listImage: ProductDetailImageModel[] = await this.$apiManager.product.listImageAsync(queryImage);
                    this.$tStore.commit( new ReservationPayload.Mutations.SetReservationBackgroundImagePath(listImage[0].pathImage));
                }
                catch
                {
                    this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(false));
                }
            }

            this.$tStore.commit(new ReservationPayload.Mutations.SetLayoutLoading(false));
        }

        async onCancelClick()
        {
            this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
        }

        async onReserveClick()
        {

            if (this.getIsBtnNextDisabled)
            {
                return;
            }

            if (!this.isReservation)
            {
                if (!this.$tStore.state.authenticationModal.isAuth && this.$tStore.state.configState.setting.license.authIsRequired)
                {
                    this.$tStore.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0, this.onReserveClick));
                    return;
                }

                this.isCreateReservationInProgress = true;

                if (!ReservationTokenModel.IsSupported())
                {
                    let payloadModal = new ModalInformativePayload.Mutations.DisplayModalInformative(
                        this.i18n(this.getRezToursLanguageEnum.unsupportedCookiesTitle),
                        this.i18n(this.getRezToursLanguageEnum.unsupportedCookiesMessage)
                    );

                    this.$tStore.commit(payloadModal);

                    this.isCreateReservationInProgress = false;
                    return;
                }

                let payload = new ReservationPayload.Actions.CreateReservation(this.product, this.productPriceDateCode, this.productPriceClassCode, this.productPriceCategoryCode, this.pricingClassCode, this.pricingCategoryCode, this.dateDepart, this.dateReturn, this.nbAdult, this.nbChild, this.nbRoom, this.listOption ?? [], this.busPickup);

                let reservCode: string;
                try
                {
                    reservCode = await this.$tStore.dispatch(payload);
                }
                catch(ex)
                {
                    if ((ex as AxiosError)?.response?.status === 401 && this.$tStore.state.configState.setting.license.authIsRequired)
                    {
                        this.isCreateReservationInProgress = false;
                        return;
                    }

                    this.hasError = true;
                    this.isCreateReservationInProgress = false;
                    return;
                }

                try
                {
                    let payload = new ReservationPayload.Actions.FetchReservation(reservCode);
                    await this.$tStore.dispatch(payload);
                }
                catch
                {
                    ReservationTokenModel.delete();
                    this.isCreateReservationInProgress = false;
                    return this.$router.replace({
                        name: RoutesEnum.NotFound
                    });
                }
            }

            this.$router.replace(
                {
                    name: RoutesEnum.PaxInfo,
                    query:
                        {
                            code: this.$tStore.state.reservationState.detail.code
                        }
                }
            );
        }

        onClickSeeMoreOffers()
        {
            if (this.$route.name !== RoutesEnum.Package)
            {
                this.$router.push({ name: RoutesEnum.Package });
            }
            else
            {
                this.$tStore.dispatch(new ProductPayload.Actions.FetchProducts());
            }
        }
    }
